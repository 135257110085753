<template>
<div class="row option">
   <div class="col-6">
      <div class="tooltip-box tooltip-box--price mb-2">
        <span v-if="option.discount > 0" class="badge-sale">{{ option.discount }}%</span>
        <tooltip-btn :tooltip="option.tooltip"/>
      </div>
      <img :src="$filters.image(option.image)" class="img-fluid"/>
    </div>
    <div class="col-6">
      <div class="option__name mb-2">{{ option.name }}</div>
      <div v-if="option.discount > 0" class="option__price-box mb-2">
        <span class="option__price option__price--discount">{{ $filters.currency(price) }}</span>
        <span class="option__price option__price--old">{{ $filters.currency(oldPrice) }}</span>
      </div>
      <div v-else class="option__price-box mb-2">
        <span class="option__price">{{ $filters.currency(price) }}</span>
      </div>
      <button class="btn btn-primary" :class="[option.selected ? 'selected' : 'not-selected']" @click="onClickOption">
        <span v-if="option.selected">Ausgewählt</span>
        <span v-else>Auswählen</span>
      </button>
    </div>
  </div>
</template>
<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
import TooltipBtn from '@/pages/configurator/common/tooltipBtn.vue'
export default {
  name: 'SelectOptionScreen',
  components: { TooltipBtn },
  props: {
    option: {
      required: true,
      type: Object
    }
  },
  setup (props) {
    const store = useStore()
    const price = computed(() => {
      const option = props.option
      if (!option) {
        return null
      }
      if (option.price) {
        if (!isNaN) {
          return option.price.discounted
        } else {
          if (option.discount > 0) {
            const discount = (Number.parseFloat(option.discount) / 100) * option.price.self
            let totalDiscount = 0
            totalDiscount = option.price.self - discount
            return totalDiscount
          } else {
            return option.price.self
          }
        }
      }
      return 0
    })
    const oldPrice = computed(() => {
      const option = props.option
      if (!option) {
        return null
      }
      if (option.price) {
        return option.price.self
      }
      return 0
    })

    function onClickOption () {
      if (props.option.disabled) {
        return
      }
      if (props.option.type === 'check') {
        store.dispatch('configurator/toggleOption', props.option)
      } else {
        store.dispatch('configurator/selectOption', props.option)
      }
    }
    return {
      price,
      oldPrice,
      onClickOption
    }
  }
}
</script>
